import React from 'react';
import { observer } from "mobx-react";
import translate from "../../i18n/translate";
import deviceStore from '../../store/deviceStore';
import Banner from '../shared/banner';
import DeviceProtection from './device-protection.component';
import Products from './products.component';

const LandingComponent = observer((props) => {
    return (
        <>
            <Banner />
            <DeviceProtection />
            <div className="container">
                <div className="section-heading-wrapper" id="productSection">
                    <h5>{translate('home.products.yourDevice')}: <span className="cyan-color">{(deviceStore.deviceMake !== deviceStore.deviceModel) && deviceStore.deviceMake} {deviceStore.deviceModel}</span></h5>
                    <h2>{translate('home.products.heading')}</h2>
                </div>
            </div>
            <div className="product-wrapper">
                <div className="container">
                    <div className="horizontal-scroll">
                        <Products canCustomerProceed={props.canCustomerProceed}></Products>
                    </div>
                </div>
            </div>
        </>
    );
})

export default LandingComponent;