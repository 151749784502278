import React from 'react';
import translate from "../../i18n/translate";
import bannerMobile from "../../assets/images/banner_home_mobile.jpg";
import bannerDesktop from "../../assets/images/banner_home_desktop.jpg";

const Banner = () => {
    const navigateTo = (e)=>{
        e.preventDefault();
        const s=document.getElementById('productSection').offsetTop - 100;
        window.scroll({top:s,behavior:"smooth"});
    };
    return (
        <div className="home-banner">
            <img src={bannerMobile} className="display-mobile" alt="" />
            <img src={bannerDesktop} className="display-desktop" alt="" />
            <div className="banner-content">
                <div className="container">
                    <p className="banner-heading font-light">{translate('home.banner.heading',{br:<br/>})}</p>
                    <div className="button-wrapper"><button type="button" onClick={navigateTo} className="btn btn-primary"><span>{translate('home.banner.btnText')}</span></button></div>
                </div>
            </div>
        </div>
    )
}

export default Banner;