export const getBillingOrCoverageDate = (dateType) => {
    const currentDate = new Date(Date.now());
    const nextDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

    const coverageDate = validateCoverageDate(currentDate, nextDate);
    const billingDate = getBillingDate(coverageDate);
    if (dateType === 'billing') return getFullDate(billingDate);
    return getFullDate(coverageDate);
}

const getMonthText = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[date.getMonth()]
}

const getDateSuffix = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}

const getBillingDate = (coverageDate) => {
    let newDate = new Date(coverageDate);
    const billingDate = new Date(newDate.setDate(newDate.getDate() - 9));  //subtract 10 days from coverage date to get billing date
    return billingDate;
}

const getFullDate = (validDate) => {
    const dd = validDate.getDate();
    const mm = getMonthText(validDate);
    const yy = validDate.getFullYear();
    return {
        date: dd,
        dateSuffix: getDateSuffix(dd),
        month: mm,
        year: yy
    }
}

const validateCoverageDate = (date1, date2) => {
    let coverageDate = date2;
    const monthDifference = date2.getMonth() - date1.getMonth();
    if (monthDifference > 1) {
        coverageDate = new Date(date2.getFullYear(), date2.getMonth(), 0);  //to get the last day of the previous month
    }
    coverageDate = new Date(coverageDate.setDate(coverageDate.getDate() - 1));  //coverage date should be less than 1 day from next month date
    return coverageDate;
}