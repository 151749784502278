import React from 'react';
import translate from "../../i18n/translate";
import instantCover from "../../assets/images/instant_cover_icon.svg";
import worldWideCover from "../../assets/images/world_wide_cover_icon.svg";
import deviceMakeModel from "../../assets/images/device_make_model_icon.svg";
import deviceTrust from "../../assets/images/device_trust_icon.svg";
const DeviceProtection = () => {
    return (
        <>
            <div className="container device-protection-section">
                <div className="row justify-content-center">
                    <div className="col-xl-11">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <h2 className="section-heading">{translate('home.protection.heading')}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <img src={instantCover} alt="" width="128" />
                                        <h2 className="heading">{translate('home.protection.instantCover.heading')}</h2>
                                        <p>{translate('home.protection.instantCover.paragraph', {br:<br/>})}</p>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6">
                                        <img src={worldWideCover} alt="" width="128" />
                                        <h2 className="heading">{translate('home.protection.worldWideCover.heading')}</h2>
                                        <p>{translate('home.protection.worldWideCover.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-6 col-md-6">
                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <img src={deviceMakeModel} alt="" width="128" />
                                        <h2 className="heading">{translate('home.protection.deviceMakeModel.heading')}</h2>
                                        <p>{translate('home.protection.deviceMakeModel.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={deviceTrust} alt="" width="128" />
                                        <h2 className="heading">{translate('home.protection.deviceTrust.heading')}</h2>
                                        <p>{translate('home.protection.deviceTrust.paragraph')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeviceProtection;